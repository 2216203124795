import Video from "../../components/Video";
import logos from "../../assets/logos.png";
import Comments from "./Comments";
import volume from "../../assets/volume-high.png";

export default function Hero() {
  const currentDate = new Date();

  const formattedDate = currentDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <section>
      <article className="p-4 py-2 pb-11 m-auto max-w-6xl">
        <article className="flex flex-col font-bold text-custom-black text-2xl md:text-3xl">
          <h1 className="text-custom">
            <mark className="text-[rgb(255,0,0)] bg-transparent">URGENTE:</mark>{" "}
            Pesquisador Alcoólatra Descobre{" "}
            <mark className="text-[rgb(255,0,0)] font-bold bg-transparent">
              “Truque Da Cerveja”
            </mark>{" "}
            Capaz De{" "}
            <mark className="text-[rgb(255,0,0)] font-bold bg-transparent">
              Secar 10kg De Barriga Em 21 Dias!
            </mark>{" "}
          </h1>

          <article className="flex flex-col mt-3">
            <h2 className="text-sm md:text-base font-bold">
              Por Dr. Márcio Felipe
            </h2>

            <h3 className="font-normal text-sm md:text-base">
              Atualizado há 30 minutos - {formattedDate}
            </h3>
          </article>

          <picture className="mt-3 self-center">
            <img
              className="w-full max-w-4xl object-cover"
              src={logos}
              alt="Logos da imprensa onde a notícia foi anunciada."
            />
          </picture>

          <Video />

          <article className="flex gap-2 md:gap-3 items-center my-6 justify-center">
            <img src={volume} alt="" className="h-4 md:h-6" />

            <h3 className="font-bold text-sm md:text-base text-custom-black text-center">
              Por favor, verifique se o som está ligado
            </h3>
          </article>
        </article>

        <Comments />
      </article>
    </section>
  );
}
